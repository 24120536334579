export const Team = () => {
  const teamData = [
    {
      name: "Albash ul Haq",
      job: "Chief Operations Officer",
      img: "img/team/01.jpg",
      paragraph:
        "Hello! I specialize in mobile-app development and project management.",
    },
    {
      name: "Faaiz Rehman",
      job: "Chief Executive Officer",
      img: "img/team/02.jpg",
      paragraph:
        "Bonjour and Salam! I studied Law in France at the Paris Sorbonne. I specialize in back-end development.",
    },
    {
      name: "Ibtisam Anwar",
      job: "Chief Technology Officer",
      img: "img/team/03.jpg",
      paragraph:
        "Hello everyone, I'm a full-stack developer and specialize in developing enterprise solutions.",
    },
    {
      name: "Maleeha Javaid",
      job: "Head of Graphic Design",
      img: "img/team/maleeha.jpeg",
      paragraph:
        "Hello and Salam! I'm a graphic designer specializing in UI/UX. I've got an eye for detail and making things look good!",
    },
    {
      name: "Maria Khan",
      job: "Head of Marketing",
      img: "img/team/04.jpg",
      paragraph:
        "Hey there! I studied Journalism and Mass Communication. I'm also an experienced front-end developer.",
    },
  ];

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet Our Management</h2>
          <p>
            People from Different Backgrounds With One Vision - Honesty and
            Customer Satisfaction
          </p>
        </div>
        <div className="row">
          {teamData.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
              <div className="thumbnail">
                <img src={d.img} alt={d.name} className="team-img" />
                <div className="caption">
                  <h4>{d.name}</h4>
                  <p>{d.job}</p>
                  <p>{d.paragraph}</p> {/* Displaying the paragraph text */}
                  {/* Add more fields for image, name, and title if needed */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// export const Team = (props) => {
//   return (
//     <div id="team" className="text-center">
//       <div className="container">
//         <div className="col-md-8 col-md-offset-2 section-title">
//           <h2>Meet the Team</h2>
//           <p>3 People from Different Backgrounds With One Vision</p>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
//                   <div className="thumbnail">
//                     {" "}
//                     <img src={d.img} alt={d.name} className="team-img" />
//                     <div className="caption">
//                       <h4>{d.name}</h4>
//                       <p>{d.job}</p>
//                       <p>{d.paragraph}</p> {/* New field for paragraph */}
//                       {/* Add more fields for image, name, and title if needed */}
//                     </div>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };
