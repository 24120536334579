import React, { useEffect, useRef } from "react";
import "../App.css";

const CounterComponent = ({ solutionsCount, websitesCount }) => {
  const solutionsCounterLabelRef = useRef(null);
  const websitesCounterLabelRef = useRef(null);

  useEffect(() => {
    solutionsCounterLabelRef.current.classList.add("zoom-animation");
    websitesCounterLabelRef.current.classList.add("zoom-animation");

    const animationTimeout = setTimeout(() => {
      solutionsCounterLabelRef.current.classList.remove("zoom-animation");
      websitesCounterLabelRef.current.classList.remove("zoom-animation");
    }, 1000);

    return () => {
      clearTimeout(animationTimeout);
    };
  }, []);

  return (
    <div className="counter-container">
      <div className="counter">
        <span ref={solutionsCounterLabelRef} className="counter-label">
          Solutions Developed: 8
        </span>
        <span className="counter-value">{solutionsCount}</span>
      </div>
      <div className="counter">
        <span ref={websitesCounterLabelRef} className="counter-label">
          Websites Created: 22
        </span>
        <span className="counter-value">{websitesCount}</span>
      </div>
    </div>
  );
};

export default CounterComponent;
