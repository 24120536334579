export const Services = () => {
  // Define an array of service data directly within the component
  const serviceData = [
    {
      name: "UI/UX Design/Audit/Optimization",
      icon: "icon-class-1", // Replace "icon-class-1" with the actual CSS class for the icon
      text: "Optimization and consultation to ensure visually appealing and user-friendly websites.",
    },
    {
      name: "Custom Software Development",
      icon: "icon-class-2", // Replace "icon-class-2" with the actual CSS class for the icon
      text: "We develop software tailored to your specific needs and requirements.",
    },
    {
      name: "Custom AI Chatbots",
      icon: "icon-class-2", // Replace "icon-class-2" with the actual CSS class for the icon
      text: "Impress prospects and customers with our 24/7 automated support at a fraction of the time and cost.",
    },
    {
      name: "Cybersecurity Services",
      icon: "icon-class-2", // Replace "icon-class-2" with the actual CSS class for the icon
      text: "Protect your software applications, data, and IT infrastructure from security threats.",
    },
    {
      name: "SEO Optimization",
      icon: "icon-class-2", // Replace "icon-class-2" with the actual CSS class for the icon
      text: "We specialize in creating mobile applications for various platforms.",
    },
    {
      name: "Digital Transformation",
      icon: "icon-class-2", // Replace "icon-class-2" with the actual CSS class for the icon
      text: "Modernize your IT infrastructure to stay competitive in the digital age.",
    },
    // Add more service data as needed
  ];

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={{ textAlign: "center" }}>Our Services</h2>
          <p style={{ textAlign: "center" }}>
            Our payment structure depends on the project and your budget. That's
            what sets us apart - we prioritize your budget over our cost.
          </p>
        </div>
        <div className="sevices-container d-flex flex-wrap justify-content-center">
          {serviceData.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              {" "}
              <i className={d.icon}></i>
              <div className="service-desc" style={{ textAlign: "center" }}>
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// export const Services = (props) => {
//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2 style={{ textAlign: 'center' }}>Our Services</h2>
//           <p style={{ textAlign: 'center' }}>
//             Our payment structure depends on the project and
//             your budget. That's what sets us apart - we prioritize your budget over our cost.
//           </p>
//         </div>
//         <div className="sevices-container d-flex flex wrap justify-content-center">
//         {/* // style={{ justifyContent: 'center' }}> */}
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   {" "}
//                   <i className={d.icon}></i>
//                   <div className="service-desc" style={{ textAlign: 'center' }}>
//                     <h3>{d.name}</h3>
//                     <p>{d.text}</p>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };
